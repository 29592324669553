import React, { useState, useMemo } from "react";
import "./question.scss";
import Button from "@components/button";
import TextInput from "@components/text-input";
import TextArea from "@components/text-area";
import success from "./img/send.svg";
import fail from "./img/no-entry.svg";
import { backendServer } from "@constants";

const Question = () => {
	const [data, setData] = useState({
		name: "",
		email: "",
		card: "",
		question: ""
	});
	const [result, setResult] = useState(null);
	const [loading, setLoading] = useState(false);

	const validate = data => {
		const { nameIsValid, emailIsValid, question } = data;
		let isValid = true;
		if (!nameIsValid || !emailIsValid || !question) {
			isValid = false;
		}
		return isValid;
	};

	let formIsValid = useMemo(() => validate(data), [data]);

	const handleChange = (e, isValid) => {
		e.preventDefault();
		if (e && e.target) {
			setData({
				...data,
				[e.target.name]: e.target.value,
				[`${e.target.name}IsValid`]: isValid
			});
		}
	};

	const renderSuccessResult = () => (
		<div className="result-block">
			<img
				alt="Сообщение успешно отправлено"
				className="result-block__result-icon"
				src={success}
			/>
			<p>Ваше сообщение успешно отправлено.</p>
		</div>
	);

	const renderFailResult = () => (
		<div className="result-block">
			<img
				alt="Во время отправки сообщения произошла ошибка"
				className="result-block__result-icon"
				src={fail}
			/>
			<p>
				Во время отправки сообщения произошла ошибка. Попробуйте повторить
				операцию позднее.
			</p>
		</div>
	);

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);

		const { name, card, email, question } = data;
		const body = {
			title: `Сообщение от пользователя ${name}`,
			message: `<p>
					Здравствуйте!
					<br />
					<br />
					Вам пришло сообщение от пользователя <strong>${name}</strong>
					с формы обратной связи social-card.ru.
					<br />
					<br />
					<strong>Номер транспортной карты:</strong> ${card}
					<br />
					<br />
					<strong>E-mail для обратной связи:</strong> ${email}
					<br />
					<br />
					<strong>Сообщение от пользователя:</strong>
					<br />
					${question}
				</p>`
		};

		fetch(`${backendServer}/api/email/send`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=utf-8"
			},
			body: JSON.stringify(body)
		})
			.then(res => {
				setLoading(false);
				if (res.status === 200) {
					setResult(renderSuccessResult());
				} else {
					setResult(renderFailResult());
				}
				window.scrollTo(0, 0);
			})
			.catch(err => {
				setLoading(false);
				setResult(renderFailResult());
				window.scrollTo(0, 0);
			});
	};

	return (
		<section className="full-width question">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="instructions__header">Задать вопрос</h2>
					</div>
				</div>
			</div>

			<div className="grid-container">
				<div className="row">
					{result ? (
						<div className="col-xs-12 col-md-offset-3 col-md-6 col-sm-offset-1 col-sm-10 question__result">
							{result}
						</div>
					) : (
						<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
							<p>
								Заполните необходимые поля в приведенной ниже форме и нажмите
								кнопку "Отправить".
							</p>
							<form autoComplete="off" onSubmit={handleSubmit}>
								<TextInput
									name="name"
									label="Ваше имя"
									value={data.name}
									onChange={handleChange}
									required
								/>
								<TextInput
									name="email"
									label="E-mail"
									value={data.email}
									onChange={handleChange}
									regexp="(.+)@(.+){2,}\.(.+){2,}"
									textError="Проверьте правильность ввода email адреса"
									required
								/>
								<TextInput
									name="card"
									label="Номер карты"
									value={data.card}
									onChange={handleChange}
								/>
								<TextArea
									name="question"
									label="Вопрос"
									value={data.question}
									onChange={handleChange}
									required
								/>
								<Button
									className="button-question-form"
									title="Отправить"
									onClick={handleSubmit}
									disabled={!formIsValid}
									loading={loading}
								/>
							</form>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default Question;
